import React, { Component } from 'react';
import { Link, Navigate, useParams } from "react-router-dom";

import { Grid, Tabs, Tab, Box, Button, Avatar, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';

import { ChevronLeft, Add, Message, Email } from '@mui/icons-material';

import LayoutHelper from 'helpers/LayoutHelper';

import Main from 'pages/sections/Main';
import Api from 'api/Api';

import CourseView from './CourseView';
import NoData from 'components/blocks/NoData';

import CourseForm from './blocks/CourseForm';

import HistoryTimeline from '../leads/blocks/HistoryTimeline';
import EmployeeActivities from '../employees/EmployeeActivities';

import EmailForm from '../misc/EmailForm';
import MsgForm from '../misc/MsgForm';
import InteraktForm from '../misc/InteraktForm';

import StudentExams from './blocks/StudentExams';
import StudentCertificates from './blocks/StudentCertificates';

export function withRouter(Children){

    return(props) => {

        const params = {params: useParams()};

        return <Children {...props} params={params} />
    }
}

class StudentView extends Component { 
    constructor(props) {
        super();
        this.state = {

            user: false,
            account_token: '',

            error_message: '',
            success_message: '',

            active_tab: 'active_courses',

            active_courses: [],
            inactive_courses: [],

            no_active_course: false,
            no_inactive_course: false,

            payment_modes: [],

            back: false,

            student_id: false,
            student: false,

            course_form: false,

            history: [],
            payments: [],
            
            no_payments: false,
            no_history: false,

            email_form_popup: false,
            msg_form_popup: false,
            interakt_popup: false,

            active_subtab: 'student-ativities'
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem('account_token');
        var user = localStorage.getItem('user');

        if(account_token && user){

            var student_id = this.props.params.params.student_id;

            user = JSON.parse(user);
            this.setState({user: user});

            if(student_id){

                this.setState({account_token: account_token, student_id: student_id});
                this.loadStudent(account_token, student_id);
            }else{

                this.setState({back: true});
            }
        }else{

            window.location = Api.url + 'logout';
        }
    }

    render () {

        var student = this.state.student;

        if(this.state.back){

            return <Navigate to="/students" />
        }

        return (

            <Main page="students_view" active_link="students" error_message={this.state.error_message} success_message={this.state.success_message}>
                
                <div className="section-header">
                    <Grid container spacing={0}>

                        <Grid item xs={12} sm={4}>

                            <Link to="/students" className="btn link">
                                <ChevronLeft />
                                <span>Back to students</span>
                            </Link>
                        </Grid>
                        <Grid item xs={12} sm={8}>

                            {!this.state.course_form &&
                                <div className="horizontal-tabs ml-20">
                                
                                    <Tabs
                                        variant="scrollable"
                                        value={this.state.active_tab}
                                        onChange={(e, value) => {
                                            this.setState({active_tab: value});

                                            if(value == 'activities'){

                                                this.setState({active_subtab: 'student-ativities'});
                                            }

                                            if(value == 'payments'){

                                                this.loadPayments(this.state.account_token, this.state.student_id);
                                            }
                                        }}
                                        aria-label="Student tabs"
                                        className="small-tabs"
                                    >
                                        <Tab label={"Active Courses ("+(this.state.active_courses.length)+")"} value="active_courses" id="vertical-tab-active_courses" aria-controls="vertical-tabpanel-active_courses" />
                                        <Tab label={"Inactive Courses ("+(this.state.inactive_courses.length)+")"} value="inactive_courses" id="vertical-tab-inactive_courses" aria-controls="vertical-tabpanel-inactive_courses" />
                                        <Tab label="Payments" value="payments" id="vertical-tab-payments" aria-controls="vertical-tabpanel-payments" />
                                        <Tab label="Activities" value="activities" id="vertical-tab-activities" aria-controls="vertical-tabpanel-activities" />

                                        {/* {this.state.user && this.state.user.job_profile === 'super_admin' && */}
                                        
                                            <Tab label="Exams" value="exams" id="vertical-tab-exams" aria-controls="vertical-tabpanel-exams" />
                                        {/* } */}

                                        <Tab label="Certificates" value="certificates" id="vertical-tab-certificates" aria-controls="vertical-tabpanel-certificates" />
                                    </Tabs>
                                </div>
                            }
                        </Grid>
                    </Grid>
                </div>

                <Grid container spacing={3}>

                    <Grid item xs={12} sm={4}>

                        <div className="info-card-primary">

                            <div className="info-card-body">

                                {student

                                    ?
                                        <>

                                            {student.profile_pic != '' &&
                                                <Avatar style={{width:100, height:100, marginTop:20}} src={student.profile_pic} />
                                            }

                                            <h2>{student.name}</h2>
                                            <span className="fs-12 cg-9">{student.added_on_formatted}</span>
                                            <ul className="info-list">

                                                <li style={{borderBottom:'1px solid rgba(0,0,0,.1)', paddingBottom:10}}>
                                                    <label><strong>Centre:</strong></label>
                                                    <span>{student.exam_center}</span>
                                                </li>
                                                <li>
                                                    <label>Phone:</label>
                                                    <Button size="small" onClick={(e) => {

                                                        this.setState({msg_form_popup: e.currentTarget})
                                                    }} startIcon={<Message />}>{student.contact_no}</Button>
                                                </li>
                                                <li>
                                                    <label>Interakt Message:</label>
                                                    <Button size="small" onClick={(e) => {

                                                        this.setState({interakt_popup: e.currentTarget})
                                                    }} startIcon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"/></svg>}>{student.contact_no}</Button>
                                                </li>
                                                <li>
                                                    <label>Email:</label>
                                                    <Button size="small" onClick={(e) => {
                                                            this.setState({email_form_popup: e.currentTarget})
                                                    }} startIcon={<Email />}>{student.email}</Button>
                                                </li>
                                                <li>
                                                    <label>Gender:</label>
                                                    <span>{student.gender_formatted}</span>
                                                </li>
                                                <li>
                                                    <label>Date of birth:</label>
                                                    <span>{student.dob_formatted}</span>
                                                </li>
                                                <li>
                                                    <label>Address:</label>
                                                    <span>{student.address}</span>
                                                </li>
                                                <li>
                                                    <label>City:</label>
                                                    <span>{student.city}</span>
                                                </li>
                                                
                                            </ul>

                                            <EmailForm
                                                email={student.email}
                                                account_token={this.state.account_token}
                                                anchorEl={this.state.email_form_popup}
                                                setSuccessMessage={(message) => {

                                                    LayoutHelper.addSuccessMessage(this, message)
                                                }}
                                                onSuccess={() => {
                            
                                                    this.setState({email_form_popup: false});
                                                }}
                                                onCancel={() => {

                                                    this.setState({email_form_popup: false})
                                                }}
                                                setErrorMessage={(message) => {
                            
                                                    LayoutHelper.addErrorMessage(this, message)
                                                }}
                                            />

                                            {this.state.msg_form_popup &&
                                            
                                                <MsgForm
                                                    title="Send SMS"
                                                    lead={{mobile_number: student.contact_no}}
                                                    mobile_number={student.contact_no}
                                                    account_token={this.state.account_token}
                                                    anchorEl={this.state.msg_form_popup}
                                                    setSuccessMessage={(message) => {

                                                        LayoutHelper.addSuccessMessage(this, message)
                                                    }}
                                                    onSuccess={() => {
                                
                                                        this.setState({msg_form_popup: false});
                                                        this.loadStudent(this.state.account_token, this.state.student_id);
                                                    }}
                                                    onCancel={() => {

                                                        this.setState({msg_form_popup: false})
                                                    }}
                                                    setErrorMessage={(message) => {
                                
                                                        LayoutHelper.addErrorMessage(this, message)
                                                    }}
                                                    source="students"
                                                    student_id={this.state.student_id}
                                                />
                                            }

                                            {this.state.interakt_popup &&
                                            
                                                <InteraktForm
                                                    title="Interakt Message"
                                                    lead={{mobile_number: student.contact_no}}
                                                    mobile_number={student.contact_no}
                                                    account_token={this.state.account_token}
                                                    anchorEl={this.state.interakt_popup}
                                                    setSuccessMessage={(message) => {

                                                        LayoutHelper.addSuccessMessage(this, message)
                                                    }}
                                                    onSuccess={() => {
                                
                                                        this.setState({interakt_popup: false});
                                                        this.loadStudent(this.state.account_token, this.state.student_id);
                                                    }}
                                                    onCancel={() => {

                                                        this.setState({interakt_popup: false})
                                                    }}
                                                    setErrorMessage={(message) => {
                                
                                                        LayoutHelper.addErrorMessage(this, message)
                                                    }}
                                                    source="students"
                                                    student_id={this.state.student_id}
                                                />
                                            }

                                            {!this.state.course_form &&
                                                <Button startIcon={<Add />} size="small" color="secondary" onClick={() => {
                                                    
                                                    this.setState({course_form: true})
                                                }}>Assign New Course</Button>
                                            }
                                        </>
                                    :
                                        null
                                }
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                    
                        {!this.state.course_form
                            ?
                            <div className="horizontal-tabs ml-20">
                                <div className="horizontal-tabs-content">
                                    
                                    <div
                                        value="active_courses"
                                        role="tabpanel"
                                        hidden={this.state.active_tab != 'active_courses'}
                                        id="vertical-tabpanel-active_courses"
                                        aria-labelledby="vertical-tab-active_courses"
                                    >
                                        <div>
                                            
                                            <div>

                                                {this.state.no_active_course &&
                                                    
                                                    <NoData message="Courses not found!" size="small"></NoData>
                                                }
                                            
                                                {this.state.active_courses && this.state.active_courses.length > 0
                                                    ?

                                                        this.state.active_courses.map((_active_course) => {

                                                            return <CourseView
                                                                account_token={this.state.account_token}
                                                                course={_active_course}
                                                                key={"active_course_" + _active_course.id}
                                                                student_id={this.state.student_id}
                                                                payment_modes={this.state.payment_modes}
                                                                updateCourseInstaments={(course, installments) => {

                                                                    var active_courses = this.state.active_courses;

                                                                    for(var i in active_courses){

                                                                        var __active_course = active_courses[i];
                                                                        if(__active_course.id == course.id){

                                                                            active_courses[i]['installments'] = installments;
                                                                        }
                                                                    }

                                                                    this.setState({active_courses: active_courses});
                                                                }}
                                                                removeActive={(course) => {

                                                                    let active_courses = this.state.active_courses;
                                                                    let inactive_courses = this.state.inactive_courses;

                                                                    const selected_course = active_courses.find(row => row.id === course.id);
                                                                    const selected_course_index = active_courses.findIndex(row => row.id === course.id);

                                                                    if(selected_course){

                                                                        inactive_courses.push(selected_course)

                                                                        active_courses.splice(selected_course_index, 1);
                                                                        this.setState({active_courses: active_courses, inactive_courses: inactive_courses})

                                                                        if(active_courses.length <= 0){

                                                                            this.setState({no_active_course: true});
                                                                        }
                                                                    }
                                                                }}
                                                                success_message={(message) => {

                                                                    LayoutHelper.addSuccessMessage(this, message)
                                                                }}
                                                                error_message={(message) => {

                                                                    LayoutHelper.addErrorMessage(this, message)
                                                                }}
                                                            />
                                                        })
                                                    :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        value="inactive_courses"
                                        role="tabpanel"
                                        hidden={this.state.active_tab != 'inactive_courses'}
                                        id="vertical-tabpanel-inactive_courses"
                                        aria-labelledby="vertical-tab-inactive_courses"
                                    >
                                        <div>
                                            
                                            <div>

                                                {this.state.no_inactive_course &&
                                                
                                                    <NoData message="Courses not found!" size="small"></NoData>
                                                }
                                            
                                                {this.state.inactive_courses && this.state.inactive_courses.length > 0
                                                    ?

                                                        this.state.inactive_courses.map((_inactive_course) => {

                                                            return <CourseView
                                                                reload={() => {

                                                                    this.loadStudent(this.state.account_token, this.state.student_id);
                                                                }}
                                                                course={_inactive_course}
                                                                key={"active_course_" + _inactive_course.id}
                                                                account_token={this.state.account_token}
                                                                student_id={this.state.student_id}
                                                                payment_modes={this.state.payment_modes}
                                                                updateCourseInstaments={(course, installments) => {

                                                                    var inactive_courses = this.state.inactive_courses;

                                                                    for(var i in inactive_courses){

                                                                        var __inactive_courses = inactive_courses[i];
                                                                        if(__inactive_courses.id == course.id){

                                                                            inactive_courses[i]['installments'] = installments;
                                                                        }
                                                                    }

                                                                    this.setState({inactive_courses: inactive_courses});
                                                                }}
                                                                success_message={(message) => {

                                                                    LayoutHelper.addSuccessMessage(this, message)
                                                                }}
                                                                error_message={(message) => {

                                                                    LayoutHelper.addErrorMessage(this, message)
                                                                }}
                                                            />
                                                        })
                                                    :
                                                        null
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        value="activities"
                                        role="tabpanel"
                                        hidden={this.state.active_tab != 'activities'}
                                        id="vertical-tabpanel-activities"
                                        aria-labelledby="vertical-tab-activities"
                                    >
                                        <div className="tab-content">
                                        
                                            <Box component="div" whiteSpace="normal">

                                                <div className="horizontal-tabs">
                                
                                                    <Tabs
                                                        variant="scrollable"
                                                        value={this.state.active_subtab}
                                                        onChange={(e, value) => {
                                                            this.setState({active_subtab: value});

                                                            if(value == 'lead-ativities'){

                                                                this.loadHistory(this.state.account_token, this.state.student_id);
                                                            }
                                                        }}
                                                        size="small"
                                                        aria-label="Student sub tabs"
                                                    >
                                                        <Tab label="Student Activities" value="student-ativities" id="vertical-tab-student-ativities" aria-controls="vertical-tabpanel-student-ativities" />
                                                        <Tab label="Lead Activities" value="lead-ativities" id="vertical-tab-lead-activities" aria-controls="vertical-tabpanel-lead-ativities" />
                                                        
                                                    </Tabs>

                                                    <div className="horizontal-tabs ml-20">
                                                        <div className="horizontal-tabs-content">
                                    
                                                            <div
                                                                value="student-ativities"
                                                                role="tabpanel"
                                                                hidden={this.state.active_subtab != 'student-ativities'}
                                                                id="vertical-tabpanel-student-ativities"
                                                                aria-labelledby="vertical-tab-student-ativities"
                                                            >

                                                                {this.state.active_tab == 'activities' && this.state.active_subtab == 'student-ativities'
                                                                    ?
                                                                        <EmployeeActivities
                                                                            account_token={this.state.account_token}
                                                                            student_id={this.state.student_id}
                                                                        />
                                                                    :
                                                                        null
                                                                }
                                                            </div>

                                                            <div
                                                                value="lead-ativities"
                                                                role="tabpanel"
                                                                hidden={this.state.active_subtab != 'lead-ativities'}
                                                                id="vertical-tabpanel-lead-ativities"
                                                                aria-labelledby="vertical-tab-lead-ativities"
                                                            >
                                                                <HistoryTimeline
                                                                    history={this.state.history}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </Box>
                                        </div>
                                    </div>

                                    <div
                                        value="payments"
                                        role="tabpanel"
                                        hidden={this.state.active_tab != 'payments'}
                                        id="vertical-tabpanel-payments"
                                        aria-labelledby="vertical-tab-payments"
                                    >
                                        <div className="tab-content">
                                        
                                            <Box component="div" whiteSpace="normal">

                                                {this.state.no_payments &&
                                                
                                                    <NoData message="Payments not found!" size="small"></NoData>
                                                }

                                                {this.state.payments.length > 0
                                                    ?
                                                        <Table style={{width:'100%'}} size="small">

                                                            <TableHead>
                                                                <TableCell align="left">Amount</TableCell>
                                                                <TableCell align="left">Paid</TableCell>
                                                                <TableCell align="left">Is EMI?</TableCell>
                                                                <TableCell align="left">Status</TableCell>
                                                                <TableCell align="left">Mode</TableCell>
                                                                <TableCell align="left">Date</TableCell>
                                                            </TableHead>
                                                            <TableBody>
                                                                {this.state.payments && this.state.payments.length > 0
                                                                    ?

                                                                        this.state.payments.map((_fee_detail, index) => {

                                                                            return (
                                                                                <React.Fragment key={"fee_" + index}>
                                                                                    <TableRow>
                                                                                        <TableCell>{_fee_detail.amount}</TableCell>
                                                                                        <TableCell>{_fee_detail.fees_paid}</TableCell>
                                                                                        <TableCell>{_fee_detail.is_emi ? 'Yes' : 'No'}</TableCell>
                                                                                        <TableCell>{_fee_detail.payment_status_label}</TableCell>
                                                                                        <TableCell>{_fee_detail.payment_mode_formatted}</TableCell>
                                                                                        <TableCell>{_fee_detail.payment_date_formatted}</TableCell>
                                                                                    </TableRow>
                                                                                </React.Fragment>
                                                                            )
                                                                        })
                                                                    :
                                                                        null
                                                                }
                                                            </TableBody>
                                                        </Table>
                                                    :
                                                        null
                                                }
                                            </Box>
                                        </div>
                                    </div>

                                    {/* {this.state.user && this.state.user.job_profile === 'super_admin' && */}
                                    
                                        <div
                                            value="exams"
                                            role="tabpanel"
                                            hidden={this.state.active_tab != 'exams'}
                                            id="vertical-tabpanel-exams"
                                            aria-labelledby="vertical-tab-exams"
                                        >
                                            <div>
                                            
                                                {this.state.active_tab == 'exams' &&

                                                    <StudentExams
                                                        account_token={this.state.account_token}
                                                        user={this.state.user}
                                                        student={this.state.student}
                                                        error_message={(message) => {
                                                    
                                                            LayoutHelper.addErrorMessage(this, message);
                                                        }}
                                                    />
                                                }
                                            </div>
                                        </div>
                                    {/* } */}

                                    <div
                                        value="certificates"
                                        role="tabpanel"
                                        hidden={this.state.active_tab != 'certificates'}
                                        id="vertical-tabpanel-certificates"
                                        aria-labelledby="vertical-tab-certificates"
                                    >
                                        <div>
                                        
                                            {this.state.active_tab == 'certificates' &&

                                                <StudentCertificates
                                                    account_token={this.state.account_token}
                                                    user={this.state.user}
                                                    student={this.state.student}
                                                    error_message={(message) => {
                                                
                                                        LayoutHelper.addErrorMessage(this, message);
                                                    }}
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        :
                            <div className="flex-center vertical">
                                <div style={{width:'60%', marginTop:30, alignItems:'center', display:'flex', flexDirection:'column'}}>
                                    <div className="main-title-block">
                                        <h1>Assign Course</h1>
                                    </div>

                                    {this.state.user &&

                                        <CourseForm
                                            showLeadForm={() => {
                                                this.setState({course_form: false})
                                            }}
                                            user={this.state.user}
                                            student_id={this.state.student_id}
                                            account_token={this.state.account_token}
                                            success_message={(message) => {
                                                        
                                                LayoutHelper.addSuccessMessage(this, message);
                                                this.loadStudent(this.state.account_token, this.state.student_id)
                                            }}
                                            error_message={(message) => {
                                                
                                                LayoutHelper.addErrorMessage(this, message);
                                            }}
                                        />
                                    }
                                </div>
                            </div> 
                    }
                    </Grid>
                </Grid>
            </Main>
        )
    }

    courseCard = (course) => {

        return (

            <div key={"active_course_" + course.id}>

                <h3>{course.title}</h3>
            </div>
        )
    }

    loadStudent = (account_token, student_id) => {
    
        var formData = new FormData();

        formData.append('account_token', account_token);
        formData.append('student_id', student_id);

        var that = this;
            
        Api.post('students/single', formData, function(data){

            if(data.status){
            
                that.setState({
                    student: data.student,
                    payment_modes: data.payment_modes
                });

                if(data.active_courses.length > 0){

                    that.setState({active_courses: data.active_courses, no_active_course: false});
                }else{

                    that.setState({no_active_course: true, active_courses: []});
                }

                if(data.inactive_courses.length > 0){

                    that.setState({inactive_courses: data.inactive_courses, no_inactive_course: false});
                }else{

                    that.setState({no_inactive_course: true, inactive_courses: []});
                }
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }
    
    loadPayments = (account_token, student_id) => {
    
        var formData = new FormData();

        formData.append('account_token', account_token);
        formData.append('student_id', student_id);

        var that = this;
            
        Api.post('students/payments', formData, function(data){

            if(data.status){
            
                if(data.payments.length){

                    that.setState({payments: data.payments, no_payments: false});
                }else{

                    that.setState({payments: [], no_payments: true});
                }
                
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }

    loadHistory = (account_token, student_id) => {
    
        var formData = new FormData();

        formData.append('account_token', account_token);
        formData.append('student_id', student_id);

        var that = this;
            
        Api.post('students/history', formData, function(data){

            if(data.status){
            
                if(data.activities){

                    that.setState({
                        history: data.activities, no_history: false
                    });
                }else{

                    that.setState({
                        history: [], no_history: true
                    });
                }
                
            }else{

                if(data.code && data.code == 'no_account'){

                    window.location = Api.url + 'logout';
                }
            }
        });
    }
}

export default withRouter(StudentView);